import { isInternalLink, isSeamlessUrl, isTruthy } from '../../utility/functions';

import { AppFragment } from 'enums/path-fragment';
import { LinkProps } from 'next/link';
import type { Url } from 'components/mobileTabNavigation';
import styles from './AnchorLink.module.scss';
import { useIsPageOfType } from 'features/sport/hooks';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

export type AnchorLinkProps = Omit<LinkProps, 'href'> & {
  id?: string;
  rel?: string;
  href?: Url;
  size?: 'large' | 'medium' | 'small';
  target?: '_blank' | '_self' | '_parent' | '_top';
  children?: React.ReactNode;
  download?: string;
  isButton?: boolean;
  className?: string;
  visualizationType?: 'primary' | 'secondary' | 'tertiary' | 'sideMenuUserPrimary' | 'sideMenuUserTertiary';
};

export const AnchorLink = (props: AnchorLinkProps) => {
  const { prefetch } = useRouter();
  // const { goSoft, goStrong } = useCustomNavigation(); // capire se tenere o rimuovere completamente
  const isLotteryPage = useIsPageOfType(AppFragment.Lotterie);
  const isGoingToLogin = useIsPageOfType(AppFragment.Login);

  const { rel, href, size, target, isButton, visualizationType, className, download, children, onClick, ...oth } =
    props ?? {};

  const { wRel, wHref, wTarger, isInternal, wDownload, wClassName } = useMemo(() => {
    const wHref = typeof href === 'string' ? href : href?.toString();

    // SOME LOTTERY PAGE MODIFIES DOCUMENT ATTRIBUTE, NAVIGATION OCCURS VIA HTTP REQUEST IN THOSE CASES
    // TODO : ANALISYS AND PROPER FIX
    // Seamless Page are better to load as document - in order to reload the whole State and session manager
    const isSeamless = isSeamlessUrl(wHref ?? '');
    const wInternal = isInternalLink(wHref);

    // internal flag is used to switch navigation mode : router.push for internal links, document.location.replace for others
    const isInternal = isGoingToLogin || isLotteryPage || isSeamless ? false : wInternal;
    const wRel = wInternal ? rel : 'noreferrer';
    const wTarger = target ?? (wInternal ? '_self' : '_blank');
    const wDownload = isTruthy(download);

    const wClassName = [
      size ? styles[size] : '',
      isButton ? styles.buttonContainer : '',
      className ?? '',
      visualizationType ? styles[visualizationType] : '',
    ].join(' ');

    return {
      wRel,
      wHref,
      wTarger,
      wDownload,
      isInternal,
      wClassName,
    };
  }, [href, isGoingToLogin, isLotteryPage, rel, target, download, size, isButton, className, visualizationType]);

  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    console.log(`UI-Navigation.trigger -> "${wHref}"`);
    if (wHref && isInternal) {
      prefetch(wHref);
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return (
    <a
      rel={wRel}
      href={wHref}
      target={wTarger}
      download={wDownload}
      className={wClassName}
      {...oth}
      suppressHydrationWarning
      onClick={onClickHandler}
    >
      {children}
    </a>
  );
};
