import { DynamicImage } from 'components/Image';
import { ImageProps } from 'next/image';
import { isNotEmpty } from 'utility/functions';
import styles from './ThemeLogo.module.scss';
import { useIsDarkTheme } from 'hooks/useIsDarkTheme';
import { useMemo } from 'react';

const keyLogo = 'faf81215-790b-4d9e-a72c-771f325d8247';
export type ThemeLogoProps = ImageProps & {
  srcDarkMode?: string;
  redirectUrlLogo?: string;
  link?: string;
};

export const Fallback = () => {
  // eslint-disable-next-line @next/next/no-img-element
  return <img key={keyLogo} className={styles.logo} src="" alt="" suppressHydrationWarning />;
};

export const ThemeLogo = ({ alt, src, width, fill, srcDarkMode, ...props }: ThemeLogoProps) => {
  const { isDarkMode } = useIsDarkTheme();

  const { imgSrc, dynProps } = useMemo(() => {
    const dynProps: Record<string, any> = {};
    if (width) {
      dynProps['width'] = width;
    } else if (fill) {
      dynProps['fill'] = fill;
    }

    const imgSrc = isDarkMode ? srcDarkMode : src;

    return {
      imgSrc,
      dynProps,
    };
  }, [fill, src, srcDarkMode, isDarkMode, width]);

  if (isNotEmpty(imgSrc)) {
    return (
      <DynamicImage
        {...props}
        {...dynProps}
        alt={alt ?? ''}
        src={`${imgSrc}`}
        key={keyLogo}
        decoding="async"
        className={styles.logo}
        suppressHydrationWarning
      />
    );
  }

  // eslint-disable-next-line @next/next/no-img-element
  return <img {...props} {...dynProps} alt={alt} className={styles.logo} key={keyLogo} suppressHydrationWarning />;
};
