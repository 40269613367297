import { createContext, useContext } from 'react';

import raise from 'utility/raise';

export type InfoAmbiente = {
  ambiente: 'ippica' | 'virtual-sport' | 'virtual-corse' | 'sport';
  pathScommessa: string[];
  /**
   * È il toggle che mostra/nasconde il header sulla cella della scommessa
   * - True nel dettaglio
   * - False in listing
   */
  scommessaTipoShowsHeader?: boolean;
  infoAggUpdateAllActionType?: string;
};

export const AmbienteContext = createContext<InfoAmbiente | null>(null);

export const useAmbienteContext = () => {
  const selectAmbiente =
    useContext(AmbienteContext) ??
    raise('useAmbienteContext must be used inside a AmbienteContext providing a valid selector');
  return selectAmbiente;
};
