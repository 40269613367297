import LegacyImage, { ImageProps as LegacyProps } from 'next/legacy/image';
import NewImage, { ImageProps as NewProps } from 'next/image';
import { isMatch, isNotEmpty } from 'utility/functions';

import { DeepPartial } from 'types/swagger';
import React from 'react';
import SVG from 'react-inlinesvg';

export type DynamicImageProps = DeepPartial<LegacyProps | NewProps>;

export const DynamicImage = (props: DynamicImageProps) => {
  const { src, onError, ...oth } = props ?? {};

  if (src && isNotEmpty(src)) {
    // eslint-disable-next-line no-unused-vars
    const { priority: _p, fill: _f, ...imgProps } = oth as any;

    if (
      // prevent next-image for SVG it has any effect
      isMatch(`${src}`, '\\.svg(\\?|$)') ||
      // prevent warning use most recent image format
      isMatch(
        `${src}`,
        '^data:image\\/(gif|png|jpeg|bmp|webp|svg\\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+\\/])+={0,2}$'
      )
    ) {
      return <img {...imgProps} src={src} />;
    } else {
      const isLegacy = ['layout', 'objectFit', 'objectPosition'].some((key) => Object.hasOwn.call(props, key));
      if (isLegacy) {
        return <LegacyImage {...(props as LegacyProps)} suppressHydrationWarning={true} />;
      }
      return <NewImage {...(props as NewProps)} suppressHydrationWarning={true} />;
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment />;
};
