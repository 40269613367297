import { useMemo } from 'react';
import { useTheme } from 'next-themes';

export interface ThemeType {
  theme?: string;
  isDarkMode: boolean;
  setTheme: (_: string) => void;
  // check:(_:string) => boolean;
}

export const useIsDarkTheme = (): ThemeType => {
  const { theme, setTheme } = useTheme();

  const check = (value?: string) => value === 'dark';

  return useMemo((): ThemeType => {
    const isDarkMode = check(theme);
    return { theme, isDarkMode, setTheme };
  }, [theme, setTheme]);
};
