import { AppFragment, AppFragmentType } from 'enums/path-fragment';
import { AvvenimentoLinkType, InfoAggiuntivaBilanciataType, InfoTipoListPayload, SportFilters } from './types';
import { AvvenimentoList, InfoTipoScommessa, ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { LiveFiltersStatus, enFase, enGolNoGol, enStatoMatch } from 'features/live/liveFilters';
import {
  SportsAvvenimentoEsposto,
  SportsCacheScommesseCoreDto,
  SportsCacheTemplateAvvenimentoDto,
  SportsCompetitor,
  SportsInfoAggData,
  SportsInfoAggIndex,
  SportsInfoEsito,
  SportsPiuGiocateTabDto,
} from 'types/swagger';
import { getUrlManifestazioneByAvvenimento, isLiveEvent, sortEsiti } from '.';
import { hasValue, isMatch, isNotEmpty, isTruthy, purgeNulls, toSportUrlCase } from 'utility/functions';
import { matchesOrario, matchesQuote } from './components/sportNav/utils';
import {
  selectAvvenimentiFactory,
  selectAvvenimentoFactory,
  selectAvvenimentoListFactory,
  selectCompetitorFactory,
  selectCompetitorsFactory,
  selectCurrentPath,
  selectDescrizioniTipoScommessaFactory,
  selectDettaglioAvvenimento,
  selectDynamicSlugFactory,
  selectDynamicTemplateFactory,
  selectedMostPlayedTab,
  selectEsitoByScommessaFactory,
  selectEsitoFactory,
  selectEsitoMapFactory,
  selectInfoAggiuntivaBilanciata,
  selectInfoAggiuntivaDataFactory,
  selectInfoAggiuntivaIndexFactory,
  selectInfoAggiuntivaIndexMapFactory,
  selectInfoAggiuntivaMetaFactory,
  selectInfoAggiuntivaMetaMapFactory,
  selectInfoAggiuntivaSummaryFactory,
  selectInfoAggSummaryMapFactory,
  selectInfoEsitoFactory,
  selectInfoEsitoMapFactory,
  selectInfoGroupScommessaFactory,
  selectInfoTemplateAvvenimento,
  selectInfoTemplateLive,
  selectInfoTemplateManifestazione,
  selectInfoTipoScommessaFactory,
  selectInfoTipoScommessaListFactory,
  selectIsFilterChanged,
  selectIsScommessaActiveFactory,
  selectIsScommessaLoadingFactory,
  selectPlayersFactory,
  selectScommessaFactory,
  selectScommessaResponseFactory,
  selectScommessaStatusFactory,
  selectSelectedFiltersFactory,
  selectTabListFactory,
  selectTabListResponseFactory,
} from './selectors';

import { ApiStatus } from 'features/api/thunkUtils';
import { KeyManagerSport } from './utils/keyManager';
import { shallowEqual } from 'fast-equals';
import { useAmbienteContext } from 'context/Ambiente';
import { useGroupContext } from 'context/Group';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'hooks/useLingUI';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { removeFilterAvvenimento, setFilterAvvenimento } from './sportSlice';
import { SportsWidgetPiuGiocateType } from 'types/swagger/sports';
import useWindowSize from 'hooks/useWindowSize';

export const useIsPageOfType = (fragment: AppFragmentType): boolean => {
  const path = useTypedSelector(selectCurrentPath);
  const result = isMatch(path, `^(\/?)${fragment}(\/|$)`);
  // console.log(`useIsPageOfType: "${path}".is("${fragment}") -> ${result}`);
  return result;
};

export const useIsLivePage = () => {
  return useIsPageOfType(AppFragment.Live);
};

export const useIsMarcatoriPage = () => {
  // NOTE: THE MARCATORI PATH CAN BE ONLY SPORT/MARCATORI
  const path = useTypedSelector(selectCurrentPath).split('/')[2];
  const result = isMatch(path, `^(\/?)${AppFragment.Marcatori}(\/|$)`);
  // console.log(`isMarcatore ~ w/ path ${path} -> ${result}`);
  return result;
};

export interface SportsAvvenimentoType extends SportsAvvenimentoEsposto {
  urlManifestazione?: string;
}

export function useInfoTemplateManifestazione<T>() {
  const {
    templateManifestazione: template,
    templateManifestazioneStatus,
    scommessaStatus,
  } = useTypedSelector(selectInfoTemplateManifestazione);

  const isTemplateLoading = templateManifestazioneStatus === ApiStatus.loading;
  const isScommessaLoading = scommessaStatus === ApiStatus.loading;

  let templateManifestazioneValid = false;
  try {
    templateManifestazioneValid = hasValue(template);
  } catch (e) {
    templateManifestazioneValid = false;
  }

  return useMemo(
    () => ({
      scommessaStatus,
      isTemplateLoading,
      isScommessaLoading,
      templateManifestazione: template as T,
      templateManifestazioneValid,
      templateManifestazioneStatus,
    }),
    [
      template,
      isScommessaLoading,
      isTemplateLoading,
      scommessaStatus,
      templateManifestazioneStatus,
      templateManifestazioneValid,
    ]
  );
}

export function useInfoTemplateAvvenimento<T>() {
  const {
    scommessaStatus,
    avvenimentoFilter,
    templateAvvenimento: template,
    templateAvvenimentoStatus,
  } = useTypedSelector(selectInfoTemplateAvvenimento);

  const isTemplateLoading = templateAvvenimentoStatus === ApiStatus.loading;
  const isScommessaLoading = scommessaStatus === ApiStatus.loading;

  let templateAvvenimentoValid = false;
  try {
    templateAvvenimentoValid = hasValue(template);
  } catch (e) {
    templateAvvenimentoValid = false;
  }

  return useMemo(
    () => ({
      scommessaStatus,
      avvenimentoFilter,
      isTemplateLoading,
      isScommessaLoading,
      templateAvvenimento: template as T,
      templateAvvenimentoValid,
      templateAvvenimentoStatus,
    }),
    [
      template,
      scommessaStatus,
      avvenimentoFilter,
      isTemplateLoading,
      isScommessaLoading,
      templateAvvenimentoValid,
      templateAvvenimentoStatus,
    ]
  );
}

export function useInfoTemplateLive<T>() {
  const { templateLive: template, scommessaStatus, templateLiveStatus } = useTypedSelector(selectInfoTemplateLive);

  const isTemplateLoading = templateLiveStatus === ApiStatus.loading;
  const isScommessaLoading = scommessaStatus === ApiStatus.loading;

  let templateLiveValid = false;
  try {
    templateLiveValid = hasValue(template);
  } catch (e) {
    templateLiveValid = false;
  }

  return useMemo(
    () => ({
      hasfilters: templateLiveValid && isTruthy(template?.hasfilters),
      templateLive: template as T,
      scommessaStatus,
      isTemplateLoading,
      isScommessaLoading,
      templateLiveValid,
      templateLiveStatus,
    }),
    [isScommessaLoading, isTemplateLoading, scommessaStatus, template, templateLiveStatus, templateLiveValid]
  );
}

export const useAvvenimento = (keyAvvenimento: string = '~nothing'): SportsAvvenimentoType => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimento = useMemo(
    () => selectAvvenimentoFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const avvenimento = useTypedSelector((state) => selectAvvenimento(state, keyAvvenimento));
  const dettaglio = useTypedSelector(selectDettaglioAvvenimento);

  const result = avvenimento ?? (dettaglio?.key === keyAvvenimento && dettaglio);

  if (result) {
    Reflect.set(result, 'urlManifestazione', getUrlManifestazioneByAvvenimento(result));
  }

  return result as SportsAvvenimentoType;
};

export const useAvvenimenti = (): AvvenimentoList => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimentoList = useMemo(
    () => selectAvvenimentoListFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const result = useTypedSelector(selectAvvenimentoList);

  return result ?? [];
};

export const useCompetitor = (avvenimentoKey: string = '~nothing', competitor: 1 | 2) => {
  const { pathScommessa: path } = useAmbienteContext();
  const selectCompetitor = useMemo(
    () => selectCompetitorFactory(selectScommessaResponseFactory(path), competitor),
    [competitor, path]
  );

  return useTypedSelector((state) => selectCompetitor(state, avvenimentoKey));
};

export const useCompetitors = (avvenimentoKey: string = '~nothing') => {
  // N.B.: selectCompetitorsFactory returns [comp1, comp2] || [description]
  const { pathScommessa: path } = useAmbienteContext();
  const selectCompetitors = useMemo(() => selectCompetitorsFactory(selectScommessaResponseFactory(path)), [path]);

  return useTypedSelector((state) => selectCompetitors(state, avvenimentoKey));
};

const avvenimentoLinkFallback: AvvenimentoLinkType = {
  shortUrl: '',
  longUrl: '',
  found: false,
  first: true,
};

export const useAvvenimentoLink = (keyAvvenimento: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimentoList = useMemo(
    () => selectAvvenimentoListFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );

  const avvenimenti = useTypedSelector(selectAvvenimentoList);

  const { list, longUrl } = useMemo(() => {
    return {
      list: avvenimenti?.map((x) => x.slug),
      longUrl: avvenimenti?.find((x) => x.key === keyAvvenimento)?.slug,
    };
  }, [avvenimenti, keyAvvenimento]);

  if (longUrl) {
    const rgx = new RegExp(`\/${keyAvvenimento}$`, 'gim');
    const shortUrl = longUrl.replace(rgx, '');

    const first =
      (list ?? [])
        .filter((x) => `${x}`.startsWith(`${shortUrl}/`))
        .sort()
        .indexOf(longUrl) < 1;

    return {
      shortUrl: toSportUrlCase(shortUrl),
      longUrl: toSportUrlCase(longUrl),
      found: true,
      first,
    };
  }

  return avvenimentoLinkFallback;
};

interface AvvenimentoCompetitorsType {
  ora?: string;
  data?: string;
  isLoading: boolean;
  competitors: Array<SportsCompetitor>;
  descrizione?: string;
  descrizioneTrKey?: string;
  descrizioneManifestazione?: string;
  descrizioneManifestazioneTrKey?: string;
}
export const useAvvenimentoCompetitors = (): AvvenimentoCompetitorsType => {
  const {
    ora: rawrHours,
    data: rawDate,
    isLoading,
    descrizione,
    descrizioneTrKey,
    firstCompetitor,
    secondCompetitor,
    descrizioneManifestazione,
    descrizioneManifestazioneTrKey,
  } = useTypedSelector(selectDettaglioAvvenimento) ?? {};

  let data = rawDate;
  try {
    if (typeof rawDate === 'string') {
      const [m, d, y] = rawDate.split('/');
      const tmpDate = new Date(`${y}-${m}-${d}`);
      data = tmpDate.toISOString().substring(0, 10).split('-').reverse().join('/');
    }
  } catch (e) {
    data = rawDate;
  }

  let ora = rawrHours;
  try {
    if (typeof rawrHours === 'string') {
      const [h, m] = rawrHours.split(':');
      ora = [h, m].join(':');
    }
  } catch (e) {
    ora = rawrHours;
  }

  const competitors = useMemo(() => {
    const result: Array<SportsCompetitor> = [];
    if (firstCompetitor) {
      result.push(firstCompetitor);
    }
    if (secondCompetitor) {
      result.push(secondCompetitor);
    }
    return result;
  }, [firstCompetitor, secondCompetitor]);

  return {
    ora,
    data,
    isLoading: isTruthy(isLoading),
    competitors,
    descrizione,
    descrizioneTrKey,
    descrizioneManifestazione,
    descrizioneManifestazioneTrKey,
  };
};

export const useLiveInfo = (keyAvvenimento: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimento = useMemo(
    () => selectAvvenimentoFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const avvenimento = useTypedSelector((state) => selectAvvenimento(state, keyAvvenimento));
  return avvenimento?.live;
};

export const useInfoTipo = (scommessaKey: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectInfoTipoScommessa = useMemo(
    () => selectInfoTipoScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const infoTipoScommessa = useTypedSelector((state) => selectInfoTipoScommessa(state, scommessaKey));

  return infoTipoScommessa as InfoTipoScommessa;
};

export const useGroup = (groupKey: string) => {
  const { pathScommessa } = useAmbienteContext();
  const selectGroupMap = useMemo(
    () => selectInfoGroupScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  return useTypedSelector((state) => selectGroupMap(state, groupKey));
};

export const useGroupGap = () => {
  const { scommessaGroupKey } = useGroupContext();
  return useMemo(() => {
    const result: Record<string, number> = {};
    if (scommessaGroupKey) {
      scommessaGroupKey
        .replace('group-', '')
        .split('_')
        .forEach((_, index) => {
          result[index + 1] = index + 1;
        });
    }
    return result;
  }, [scommessaGroupKey]);
};

export const useInfoTipoList = (scommessaKey: Array<string | number>, computeHeader = false): InfoTipoListPayload => {
  const { pathScommessa } = useAmbienteContext();

  const { selectInfoTipoScommessa, selectInfoAggIndex } = useMemo(() => {
    const selectInfoAggIndex = selectInfoAggiuntivaIndexMapFactory(selectScommessaResponseFactory(pathScommessa));
    const selectInfoTipoScommessa = selectInfoTipoScommessaListFactory(selectScommessaResponseFactory(pathScommessa));

    return {
      selectInfoAggIndex,
      selectInfoTipoScommessa,
    };
  }, [pathScommessa]);

  const infoAggIndexMap = useTypedSelector(selectInfoAggIndex, shallowEqual);

  const selectorKey: Array<string> = useMemo(() => (scommessaKey ?? []).map((x) => `${x}`), [scommessaKey]);
  const list = useTypedSelector((state) => selectInfoTipoScommessa(state, selectorKey), shallowEqual);
  const infoTipoScommessaList = list.filter((infoTipoScommessa) => !!infoTipoScommessa) as Array<InfoTipoScommessa>;

  const headerSequence: Record<string, Array<number>> = useMemo(() => {
    // hpybet -> uber / under
    // given infoTipoScommessaKey, retrieve first available esitoKeyList in order to get headers sequence sorted
    const result: Record<string, Array<number>> = {};
    if (computeHeader && isTruthy(scommessaKey?.length)) {
      // all involved keys
      let allKeys = Object.keys(infoAggIndexMap ?? {}).filter((indexKey) =>
        scommessaKey.some((key) => indexKey.includes(`-${key}-`))
      );

      for (const key of scommessaKey) {
        if (Object.hasOwn(result, key)) {
          continue;
        }

        const infoAggKey = allKeys.find((x) => {
          if (x.includes(`-${key}-`)) {
            const { esitoKeyList } = Reflect.get(infoAggIndexMap ?? {}, x) ?? {};
            return isTruthy(esitoKeyList?.length);
          }
          return false;
        });

        if (isNotEmpty(infoAggKey)) {
          const { esitoKeyList } = Reflect.get(infoAggIndexMap ?? {}, infoAggKey!) ?? {};
          const sequence = esitoKeyList?.map((x) => x.split('-').pop()).map((x) => Number(x) - 1);
          result[key] = sequence ?? [];
        }
      }
    }
    return result;
  }, [infoAggIndexMap, scommessaKey, computeHeader]);

  return { headerSequence, infoTipoScommessaList };
};

export const useEsitoMap = () => {
  const { pathScommessa } = useAmbienteContext();
  const selectEsitoMap = useMemo(
    () => selectEsitoMapFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const mapEsiti = useTypedSelector(selectEsitoMap);
  return mapEsiti;
};

export const useInfoEsitoMap = () => {
  const { pathScommessa } = useAmbienteContext();
  const selectInfoEsitoMap = useMemo(
    () => selectInfoEsitoMapFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const mapInfoEsiti = useTypedSelector(selectInfoEsitoMap);
  return mapInfoEsiti;
};

export const useInfoAggiuntivaMeta = (infoAggKey: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectInfoAggiuntivaMeta = useMemo(
    () => selectInfoAggiuntivaMetaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );

  return useTypedSelector((state) => selectInfoAggiuntivaMeta(state, infoAggKey));
};

export const useInfoAggiuntivaIndex = (infoAggKey: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectInfoAggiuntivaIndex = useMemo(
    () => selectInfoAggiuntivaIndexFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );

  return useTypedSelector((state) => selectInfoAggiuntivaIndex(state, infoAggKey));
};

export const useScommessa = (scommessaKey: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectScommessa = useMemo(
    () => selectScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const scommessa = useTypedSelector((state) => selectScommessa(state, scommessaKey));
  return scommessa;
};

export const useEsito = (keyEsito: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectEsito = useMemo(() => selectEsitoFactory(selectScommessaResponseFactory(pathScommessa)), [pathScommessa]);
  const esito = useTypedSelector((store) => selectEsito(store, keyEsito));
  return esito;
};

export const useInfoEsito = (keyInfoEsito: string = '~nothing'): SportsInfoEsito | undefined => {
  const { pathScommessa } = useAmbienteContext();
  const selectInfoEsito = useMemo(
    () => selectInfoEsitoFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  return useTypedSelector((store) => selectInfoEsito(store, keyInfoEsito));
};

export const useEsitoByScommessa = (scommessaKey: string) => {
  const { pathScommessa } = useAmbienteContext();
  const selectEsitoByScommessa = useMemo(
    () => selectEsitoByScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const esito = useTypedSelector((store) => selectEsitoByScommessa(store, scommessaKey));
  return esito;
};

export const useInfoAggiuntivaData = (infoAggKey: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectInfoAggiuntivaData = useMemo(
    () => selectInfoAggiuntivaDataFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );

  return useTypedSelector((state) => selectInfoAggiuntivaData(state, infoAggKey));
};

export const useInfoAggiuntivaDataByScommessaKey = (scommessaKey: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectScommessa = useMemo(() => selectScommessaResponseFactory(pathScommessa), [pathScommessa]);

  return useTypedSelector((state) => {
    const scommessa = selectScommessa(state);
    const { infoAggIndex, infoAggData } = scommessa ?? {};
    const { nextInfoAgg } = Reflect.get(infoAggIndex ?? {}, scommessaKey) ?? {};
    const [infoAggiuntivaKey] = nextInfoAgg ?? [];
    const data = Reflect.get(infoAggData ?? {}, infoAggiuntivaKey ?? '~nothing') ?? {};
    return {
      infoAggiuntivaKey,
      ...data,
    };
  }, shallowEqual);
};

export const useInfoAggiuntivaSummaryByInfoTipoScommessaKey = (infoTipoScommessaKey: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();

  const selectInfoAggSummary = useMemo(
    () => selectInfoAggiuntivaSummaryFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );

  const infoAggIndex = useTypedSelector((state) => selectInfoAggSummary(state, infoTipoScommessaKey));

  // console.log('🚀 ~ useInfoAggiuntivaSummaryByScommessaKey ~ infoAggIndex:', infoAggIndex);
  return infoAggIndex;
};

export const useEsitoKeylistByInfoAggKey = (infoAggKey: string) => {
  // PAS : TODO : verificare se possibile semplificare usando useInfoAggiuntivaData
  const { pathScommessa } = useAmbienteContext();

  const selectInfoAggIndex = useMemo(
    () => selectInfoAggiuntivaIndexFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );

  const infoAggIndex = useTypedSelector((state) => selectInfoAggIndex(state, infoAggKey));
  return infoAggIndex?.esitoKeyList ?? [];
};

export const useIsOverTree = (tipoScommessaId: string) => {
  const infoTipoScommessa = useInfoTipo(tipoScommessaId);
  return infoTipoScommessa?.numeroEsiti > 3;
};

export const useDescrizioniScommesse = (keys: (string | number)[]) => {
  const { pathScommessa } = useAmbienteContext();
  const selectDescrizioniScommesse = useMemo(
    () => selectDescrizioniTipoScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const descrizioni = useTypedSelector((state) => selectDescrizioniScommesse(state, keys));
  return descrizioni;
};

export const useScommessaStatus = () => {
  const selectScommessaStatus = useMemo(selectScommessaStatusFactory, []);
  const status = useTypedSelector(selectScommessaStatus);

  return {
    isUninitialized: isNotEmpty(status) ? false : true,
    isIdle: status === ApiStatus.idle,
    isError: status === ApiStatus.failed,
    isLoading: status === ApiStatus.loading,
  };
};

/**
 * @deprecated in favore di useScommessaStatus
 *
 * */
export const useIsScommessaLoading = () => {
  const selectIsScommessaLoading = useMemo(selectIsScommessaLoadingFactory, []);
  return useTypedSelector(selectIsScommessaLoading);
};

export const useAvvenimentoList = (keys: Array<string>, applySorting = false) => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimentoList = useMemo(
    () => selectAvvenimentoListFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );

  const data = useTypedSelector((state) => selectAvvenimentoList(state));

  return useMemo(() => {
    const skipFilters = keys.some((x) => x === '*');
    let result = data ?? [];

    if (!skipFilters) {
      result = result
        .filter(({ key }) => !!key && keys.includes(key))
        .filter((item, idx, lst) => lst?.findIndex((x) => x.key === item.key) === idx);
    }

    if (applySorting && result?.length > 1) {
      return [...result].sort((a, b) => {
        const aTime = new Date(a.dataOra ?? '2020-10-10');
        const bTime = new Date(b.dataOra ?? '2020-10-10');
        return Number(bTime) > Number(aTime) ? -1 : 1;
      });
    }

    return result;
  }, [applySorting, data, keys]);
};

// evalLiveFilters is used as "array.filter" function, so, it should identify the "false" cases to remove occurrence from the list
// matches passing all checks are the one to keep
const evalLiveFilters = (avvenimento: SportsAvvenimentoEsposto, filter: LiveFiltersStatus): boolean => {
  const live = Reflect.get(avvenimento ?? {}, 'live');

  if (live) {
    const { firstCompetitor, secondCompetitor } = live.score ?? {};
    const { minutaggio, sommaGol, diffGol, fase, statoMatch, golNoGol } = filter ?? {};

    const n1 = Number(firstCompetitor);
    const n2 = Number(secondCompetitor);

    if (isTruthy(fase?.length)) {
      // check by fase is applied by
      // list contains for standard check
      let ckFase = fase?.some((x) => isMatch(live.status, x));

      if (fase?.includes(enFase.Other)) {
        // list does not contains for other check
        ckFase =
          ckFase &&
          ([enFase.FirstHalf, enFase.Breack, enFase.SecondHalf].some((x) => isMatch(live.status, x)) ? false : true);
      }

      // return is executed only in ca  se of not match
      // otherwise next condition should be evaluate
      if (!ckFase) return false;
    }

    if (isTruthy(diffGol?.length)) {
      // get goals diff
      let diff = n1 > n2 ? n1 - n2 : n2 - n1;
      if (diff > 4) {
        // since "> 3" is assigned to 4, limit diff to 4
        diff = 4;
      }

      // if selected values does not include diff value: found the exit case
      // otherwise next condition should be evaluate
      if (!diffGol?.includes(diff)) {
        return false;
      }
    }

    if (isTruthy(sommaGol?.length)) {
      // get goals sum
      let somma = n1 + n2;
      if (somma > 4) {
        // since "> 3" is assigned to 4, limit sum to 4
        somma = 4;
      }

      // if selected values does not include sum value: found the exit case
      // otherwise next condition should be evaluate
      if (!sommaGol?.includes(somma)) {
        return false;
      }
    }

    if (isTruthy(statoMatch?.length)) {
      let ck1 = true;
      if (statoMatch?.includes(enStatoMatch.Home)) {
        ck1 = n1 > n2;
      }

      let ck2 = true;
      if (statoMatch?.includes(enStatoMatch.Balance)) {
        ck2 = n1 === n2;
      }

      let ck3 = true;
      if (statoMatch?.includes(enStatoMatch.Guest)) {
        ck3 = n1 < n2;
      }

      let ckStatoMatch = ck1 || ck2 || ck3;

      // if selected result does not match result: found the exit case
      // otherwise next condition should be evaluate
      if (!ckStatoMatch) {
        return false;
      }
    }

    if ((golNoGol ?? []).length === 1) {
      // goal / no-goal is evaluated only if we have 1 item selected (2 means "all" as per 0 selection)
      const [selection] = golNoGol ?? [];

      // count "zero" occurrence in the results
      const nZero = [n1 ?? 0, n2 ?? 0].filter((x) => x === 0).length;

      switch (selection) {
        case enGolNoGol.NoGoal: {
          // NoGoal means at last one (or both) competitors has 0 goals
          if (nZero === 0) {
            // if counter of zero = 0: found the exit case
            return false;
          }
          break;
        }
        default: {
          // Goal means none of the competitors has 0 goals
          if (nZero > 0) {
            // if counter of zero > 0: found the exit case
            return false;
          }
          break;
        }
      }

      // next condition should be evaluate
    }

    if (isTruthy(minutaggio?.length)) {
      const lst: Array<boolean> = [];
      const time = Number(live.matchTime);

      for (const value of minutaggio ?? []) {
        const { from, to } = value;

        const nTo = Number(to);
        const nFrom = Number(from);

        if (isNaN(time)) {
          if (isNaN(nTo)) {
            if (to === enFase.Breack) {
              if (!isMatch(live.status, enFase.Breack)) {
                lst.push(false);
                continue;
              }
            } else {
              if (!isMatch(live.status, enFase.SecondHalf)) {
                lst.push(false);
                continue;
              }
            }
          }
        } else {
          if (time < nFrom) {
            lst.push(false);
            continue;
          }

          if (isNaN(nTo)) {
            if (to === enFase.Breack) {
              if (![enFase.FirstHalf, enFase.Breack].some((x) => isMatch(live.status, x))) {
                lst.push(false);
                continue;
              }
            } else {
              if (!isMatch(live.status, enFase.SecondHalf)) {
                lst.push(false);
                continue;
              }
            }
          } else if (time > nTo) {
            lst.push(false);
            continue;
          }
        }
        lst.push(true);
      }

      return lst.includes(true);
    }
  }

  return true;
};

const evalSportFilters = (avvenimento: SportsAvvenimentoEsposto, filter: SportFilters): boolean => {
  if (isLiveEvent(avvenimento)) return false;

  let result = true;

  const filtro = Reflect.get(avvenimento ?? {}, 'filtro');
  if (filtro) {
    const { orario, quota } = filter ?? {};
    const ckQuota = quota?.length < 2 || matchesQuote(filtro, quota[0], quota[1]);
    const ckOrario = matchesOrario(filtro, orario ?? 'default');
    result = ckQuota && ckOrario;
  }

  return result;
};

export const useFilteredAvvenimentoList = (avvenimentoList: AvvenimentoList) => {
  const selectedTab = useTypedSelector(selectedMostPlayedTab);
  const isLive = useIsPageOfType(AppFragment.Live) || selectedTab === SportsWidgetPiuGiocateType.Live;
  const isSport = useIsPageOfType(AppFragment.Sport);

  const selectSelectedFilters = useMemo(selectSelectedFiltersFactory, []);
  const isFilterChanged = useTypedSelector(selectIsFilterChanged);

  // filters are mutually exclusive
  const { selectedFilters, selectedFiltersLive } = useTypedSelector(selectSelectedFilters, shallowEqual);

  const result = useMemo(() => {
    if (isTruthy(isFilterChanged)) {
      return avvenimentoList?.filter((avvenimento) => {
        if (isLive) {
          return evalLiveFilters(avvenimento, selectedFiltersLive);
        }

        return evalSportFilters(avvenimento, selectedFilters);
      });
    } else if (isSport) {
      if (isLive) {
        return avvenimentoList;
      } else {
        return avvenimentoList?.filter((avvenimento) => {
          return isLiveEvent(avvenimento) ? false : true;
        });
      }
    }
    return avvenimentoList;
  }, [isFilterChanged, isSport, avvenimentoList, isLive, selectedFilters, selectedFiltersLive]);

  return result;
};

export const useFilteredAvvenimentoByKey = (keys: Array<string>) => {
  const { pathScommessa } = useAmbienteContext();
  const { list, selectAvvenimenti } = useMemo(() => {
    const list = (keys ?? []).map((x) => new KeyManagerSport(x).avvenimentoKey);
    const selectAvvenimenti = selectAvvenimentiFactory(selectScommessaResponseFactory(pathScommessa));

    return {
      list,
      selectAvvenimenti,
    };
  }, [pathScommessa, keys]);

  const avvenimenti = useTypedSelector((state) => selectAvvenimenti(state, list));
  const validOnes = useFilteredAvvenimentoList(avvenimenti ?? []);

  const result = useMemo(() => {
    const validKeys = validOnes?.map((x) => x.key);
    return keys?.filter((key) => validKeys?.some((x) => isMatch(key, `^${x}(-*)`)));
  }, [validOnes, keys]);

  return result;
};

export const usePlayers = (infoTipoScommessaKeys: Array<string>) => {
  const { pathScommessa: path } = useAmbienteContext();
  const selectPlayers = useMemo(
    () => selectPlayersFactory(selectScommessaResponseFactory(path), infoTipoScommessaKeys),
    [path, infoTipoScommessaKeys]
  );
  const players = useTypedSelector((state) => selectPlayers(state));
  return players;
};

export const usePlayerData = (playerKey: string) => {
  const { pathScommessa: path } = useAmbienteContext();
  const selectPlayer = useMemo(() => selectInfoAggiuntivaDataFactory(selectScommessaResponseFactory(path)), [path]);
  const player = useTypedSelector((state) => selectPlayer(state, playerKey));
  return player;
};

export const useFilteredAvvenimentoCount = () => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimentoList = useMemo(() => {
    return selectAvvenimentoListFactory(selectScommessaResponseFactory(pathScommessa));
  }, [pathScommessa]);

  const avvenimenti = useTypedSelector(selectAvvenimentoList);
  const validOnes = useFilteredAvvenimentoList(avvenimenti ?? []);

  const keys = useMemo(() => {
    return validOnes?.map((x) => x?.key)?.filter((x, idx, lst) => lst.indexOf(x) === idx);
  }, [validOnes]);

  return {
    keys,
    counter: keys?.length ?? 0,
    // rawCounter: avvenimenti?.length ?? 0
  };
};

export const useDynamicTempate = <T>() => {
  const { pathScommessa } = useAmbienteContext();

  const { slugSelector, templateSelector } = useMemo(() => {
    const path = [...pathScommessa];
    path.pop();

    const pathResolver = selectScommessaResponseFactory(path);

    const slugSelector = selectDynamicSlugFactory(pathResolver);
    const templateSelector = selectDynamicTemplateFactory(pathResolver);

    return {
      slugSelector,
      templateSelector,
    };
  }, [pathScommessa]);

  const slug = useTypedSelector(slugSelector);
  const template = useTypedSelector(templateSelector);

  return {
    slug,
    template: template as T,
  };
};

/*********************************************************************************************
 * Data una lista di chiavi scommessa complete, ritorna se almeno una delle scommesse è attiva *
 *********************************************************************************************/
export const useIsAtLeastOneActive = (keyList?: Array<string>, deepToEsiti = false) => {
  const { pathScommessa } = useAmbienteContext();

  const selectIsScommessaActive = useMemo(() => {
    return selectIsScommessaActiveFactory(selectScommessaResponseFactory(pathScommessa), { keyList, deepToEsiti });
  }, [pathScommessa, deepToEsiti, keyList]);

  return useTypedSelector((state) => selectIsScommessaActive(state));
};

export const useIsInfoAggActive = (infoAggKey: string = '~nothing') => {
  return useIsAtLeastOneActive([infoAggKey]);
};

export const useIsInfoAggActiveByScommessaKey = (scommessaKey: string = '~nothing') => {
  const { nextInfoAgg } = useInfoAggiuntivaIndex(scommessaKey) ?? {};
  return useIsAtLeastOneActive(nextInfoAgg ?? []);
};

export const useNextInfoAggSortedByEsito = (infoAggiuntivaKey: string = '~nothing') => {
  const { t } = useTranslation();
  const { pathScommessa } = useAmbienteContext();

  const infoAggIndex = useInfoAggiuntivaIndex(infoAggiuntivaKey);

  const { tipoScommessaId } = useMemo(() => new KeyManagerSport(infoAggiuntivaKey), [infoAggiuntivaKey]);
  const infoTipoScommessa = useInfoTipo(tipoScommessaId);

  const selectScommessa = useMemo(() => selectScommessaResponseFactory(pathScommessa), [pathScommessa]);

  return useTypedSelector((state) => {
    let esitoKeyList: Array<string> = [];

    const getInfoAggData = (state: ScommessaResponse | SportsCacheScommesseCoreDto, key: string): SportsInfoAggData => {
      // const path = ['infoAggData', key];
      // return dynamicDataLoader<{}, SportsInfoAggData>(state, path, true) ?? ({} as SportsInfoAggData);
      return (state?.infoAggData?.[key] ?? {}) as SportsInfoAggData;
    };

    const getInfoAggIndex = (
      state: ScommessaResponse | SportsCacheScommesseCoreDto,
      key: string
    ): SportsInfoAggIndex => {
      // const path = ['infoAggIndex', key];
      // return dynamicDataLoader<{}, SportsInfoAggIndex>(state, path, true) ?? ({} as SportsInfoAggIndex);
      return (state?.infoAggIndex?.[key] ?? {}) as SportsInfoAggIndex;
    };

    const lst: Record<string, Array<string>> = {};
    const scommessa = selectScommessa(state) ?? {};

    const { esitoMap, infoEsitoMap } = (scommessa ?? {}) as ScommessaResponse;

    // TODO : REVIEW
    const { nextInfoAgg } = infoAggIndex ?? {};

    nextInfoAgg?.map((childKey) => {
      const { isActive } = getInfoAggData(scommessa, childKey);
      const { esitoKeyList: childKeys } = getInfoAggIndex(scommessa, childKey);

      if (isTruthy(isActive)) {
        lst[childKey] = childKeys ?? [];
        esitoKeyList = esitoKeyList.concat(childKeys ?? []);
      } else {
        lst[childKey] = [];
      }
    });

    const sortedEsiti = sortEsiti({ t, esitoKeyList, esitoMap, infoEsitoMap, infoTipoScommessa });

    return nextInfoAgg?.sort((a, b) => {
      const wa = sortedEsiti.findIndex((x) => lst[a]?.includes(x));
      const wb = sortedEsiti.findIndex((x) => lst[b]?.includes(x));
      return wb > wa ? -1 : 1;
    });
  }, shallowEqual);
};

interface SortHeadersProps {
  gap?: Record<string, number>;
  keys?: Array<string>;
  headers?: Array<string>;
  headersTrKey?: Array<string>;
}

interface HeaderType {
  key: string;
  header?: string;
  headerTrKey?: string;
}

const _sortHeaders = ({ gap, keys = [], headers = [], headersTrKey = [] }: SortHeadersProps): Array<HeaderType> => {
  return (keys ?? []).map((key) => {
    const esitoId = `${key}`.split('-').pop();
    const index = Number(esitoId) - (Reflect.get(gap ?? {}, esitoId!) ?? 1);
    return {
      key,
      header: headers?.[index],
      headerTrKey: headersTrKey?.[index],
    };
  });
};

export const useEsitoListSorted = (infoAggiuntivaKey: string = '~nothing') => {
  const { t } = useTranslation();

  const esitoMap = useEsitoMap();
  const infoAggIndex = useInfoAggiuntivaIndex(infoAggiuntivaKey);
  const infoEsitoMap = useInfoEsitoMap();

  const { tipoScommessaId } = new KeyManagerSport(infoAggiuntivaKey);
  const infoTipoScommessa = useInfoTipo(tipoScommessaId);

  // return useMemo(() => {
  const { esitoKeyList } = infoAggIndex ?? {};
  return sortEsiti({ t, esitoKeyList, esitoMap, infoEsitoMap, infoTipoScommessa });
  //}, [esitoMap, infoAggIndex, infoEsitoMap, infoTipoScommessa, t]);
};

export const useEsitoHeaders = (
  scommessaKey: string,
  infoAggiuntivaKey: string = '~nothing',
  gap?: Record<string, number>,
  activeOnly = true
) => {
  const esitoMap = useEsitoMap();
  const infoAggData = useInfoAggiuntivaData(infoAggiuntivaKey);
  const infoAggIndex = useInfoAggiuntivaIndex(infoAggiuntivaKey);

  const { tipoScommessaId } = new KeyManagerSport(scommessaKey);
  const infoTipoScommessa = useInfoTipo(tipoScommessaId);

  return useMemo(() => {
    const { isActive } = infoAggData ?? {};
    const { esitoKeyList } = infoAggIndex ?? {};

    let keys = [...Array(infoTipoScommessa?.numeroEsiti ?? 1)].map((_, idx) => `${infoAggiuntivaKey}-${1 + idx}`);

    if (activeOnly || purgeNulls(gap) !== undefined) {
      keys = isTruthy(isActive)
        ? (esitoKeyList ?? []).filter((esitoKey) => {
            const { quota, isActive } = Reflect.get(esitoMap ?? {}, esitoKey) ?? {};
            if (isTruthy(isActive)) {
              return isTruthy(quota);
            }
            return false;
          })
        : [];
    }

    return _sortHeaders({ keys, gap, ...infoTipoScommessa });
  }, [infoAggData, infoAggIndex, infoTipoScommessa, activeOnly, gap, infoAggiuntivaKey, esitoMap]);
};

export const useInfoTipoScommessaInformation = (infoTipoScommesseKeyListTab: Array<string>) => {
  const { infoTipoScommessaList } = useInfoTipoList(infoTipoScommesseKeyListTab);
  const infoTipoScommessaKeyList = useMemo(
    () => infoTipoScommessaList.filter(({ key }) => !!key).map(({ key }) => key!),
    [infoTipoScommessaList]
  );
  const infoTipoScommessaDescription = useDescrizioniScommesse(infoTipoScommessaKeyList);

  return {
    infoTipoScommessaList,
    infoTipoScommessaKeyList,
    infoTipoScommessaDescription,
  };
};

export const useInfoAggiuntivaBilanciata = (scommessaKey: string = '~nothing'): InfoAggiuntivaBilanciataType => {
  const { t } = useTranslation();

  const { pathScommessa, scommessaTipoShowsHeader } = useAmbienteContext();

  const selectInfoAggiuntiva = useMemo(
    () => selectInfoAggiuntivaBilanciata(t, selectScommessaResponseFactory(pathScommessa), scommessaTipoShowsHeader),
    [pathScommessa, scommessaTipoShowsHeader, t]
  );

  return useTypedSelector((state) => selectInfoAggiuntiva(state, scommessaKey));
};

interface TabListType {
  count: number;
  tabList: Array<SportsPiuGiocateTabDto>;
}
export const useTabList = (): TabListType => {
  const { pathScommessa } = useAmbienteContext();
  const stateSlice = useMemo(() => selectTabListResponseFactory(pathScommessa), [pathScommessa]);
  const selectTabList = useMemo(() => selectTabListFactory(stateSlice), [stateSlice]);
  const tabList = useTypedSelector(selectTabList);

  const count = useMemo(
    () =>
      tabList
        ?.flatMap((tab) => tab.contentTabList?.flatMap((content) => content.avvenimentoList?.map((x) => x.key)))
        .filter((x) => !!x).length,
    [tabList]
  );

  return {
    count,
    tabList,
  };
};

export const useFilterSelection = (filterValue: string, checkedItems: Record<string, boolean>) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (filterValue) {
      if (checkedItems?.[filterValue]) {
        dispatch(setFilterAvvenimento(filterValue));
      } else if (checkedItems?.[filterValue] === false) {
        // necessario per non eseguire in caso di undefined
        dispatch(removeFilterAvvenimento(filterValue));
      }
    }
  }, [checkedItems, filterValue, dispatch]);
};

export const useIsHeaderVisible = () => {
  const { templateAvvenimento } = useInfoTemplateAvvenimento<SportsCacheTemplateAvvenimentoDto>();
  const { isMobile } = useWindowSize();

  return useMemo(
    () => (isTruthy(Object.keys(templateAvvenimento ?? {}).length) ? true : isMobile),
    [isMobile, templateAvvenimento]
  );
};

export const useIsAvvenimento = () => {
  const { templateAvvenimento } = useInfoTemplateAvvenimento<SportsCacheTemplateAvvenimentoDto>();
  const isAvvenimento = useMemo(() => isTruthy(Object.keys(templateAvvenimento ?? {}).length), [templateAvvenimento]);
  // console.log('🚀 ~ useIsAvvenimento ~ isAvvenimento:', isAvvenimento);

  return isAvvenimento;
};
